.counter-key-number{
  position: absolute;
  font-family: $mulish;
  left: 80px;
  @include break-max(1440px){
    left: 50px;
  }

  @include break-max($tab){
    left: 15px;
  }

  &.invert-pos{
    left: auto;
    right: 80px;

    @include break-max(1440px){
      right: 50px;
    }
    @include break-max($tab){
      right: 15px;
    }
  }


  @include break-max(1200px){
    display: none;
  }

  .counter-key-number--body{
    position: relative;
    padding: 0 15px;


    .counter-key-number--front-text{
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $primary-blue;
      opacity: 0.3;
      font-weight: $bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1;

      @include break-max($tab){
        font-size: 22px;
      }
    }

    .counter-key-number--back-number{
      min-width: 270px;
      text-align: center;
      font-weight: $bold;
      white-space: nowrap;
      color: $second-brand-color;
      font-size: 230px;
      line-height: 182px;

      .key-number{
        line-height: 180px;
      }

      @include break-max(1440px){
        min-width: 200px;
        font-size: 180px;
        line-height: 180px;

        .key-number{
          line-height: 180px;
        }
      }

      &.invert-color{
        color: #fff;
      }
    }
  }
}